<template>
  <div class="container">
    <div class="contact-box">
      <div class="box-title">
        <div class="title">
          联系我们
        </div>
      </div>
      <div class="content-box">
        <div class="content">
          <p>
            江西智慧云测安全检测中心股份有限公司
          </p>
          <p>
            地址：江西省鹰潭市高新技术产业开发区炬能大厦19楼
          </p>
          <p>
            电话：0701-6680729
          </p>
          <p>
            邮箱：lichen@dplslab.com
          </p>
          <p>
            邮编：335000
          </p>
        </div>
      </div>
    </div>
    <div class="contact-box">
      <div class="box-title">
        <div class="title">
          投诉热线
        </div>
      </div>
      <div class="content-box">
        <div class="content">
          <p>
            电话座机：0701-6680729
          </p>
          <p>
            投诉邮箱：lihb@dplslab.com
          </p>
          <p>
            手机号码：18600002720
          </p>
        </div>
      </div>
    </div>
    <div class="map-box">
      <div class="box-title">
        <div class="title">
          公司地图
        </div>
      </div>
      <div class="content-box">
        <img src="../assets/res/map.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  min-height: 840px;
  width: 1170px;
  background-color: #fff;
  margin: 20px auto;
  padding: 80px 20px;

  .box-title {
    width: 100%;
    border-bottom: 2px #999 solid;
    .title {
      margin-bottom: -2px;
      width: 80px;
      line-height: 32px;
      color: #333;
      font-size: 14px;
      font-weight: 400;
      text-shadow: none;
      border-bottom: 2px #409eff solid;
    }
  }

  .contact-box {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    .content {
      margin: 20px 0 0 10px;
      p {
        font-size: 14px;
        line-height: 1.8;
        padding: 0;
        margin: 0;
        white-space: normal;
        word-wrap: break-word;
        word-break: normal;
        overflow: hidden;
        width: 100%;
        text-align: left;
      }
    }
  }

  .map-box {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    .content-box {
      margin: 20px 0 10px 0;
    }
  }
}
</style>
